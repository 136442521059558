import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import { Link, useHistory } from '@deal/router'
import { useExperiment } from '@deal/experiment-js'
import { useScrollPosition } from '@deal/dom-hooks'
import { Button, Image } from '@deal/components'
import { ChatContext } from '@deal/chat-firebase'
import { Area, BrandLogo, Grid } from '@deal/bluxome'
import { buildCategoryOrProductListingPageUrl } from '#src/app/services/search/buildCategoryOrProductListingPageUrl'
import { formatPathUrl, useCreateRequestPath } from '#src/app/services/path'
import useFeedHomePageEligibilityCheck from '#src/app/services/for-you/hooks/useFeedHomePageEligibilityCheck'
import { getActiveExpertCountForCategory } from '#src/app/services/categories'
import { isAILead } from '#src/app/services/aiChat/isAIConversation'
import useShowSearchBar from '#src/app/hooks/useShowSearchBar'
import useReferralUrl from '#src/app/hooks/useReferralUrl'
import { useProductListingLocation } from '#src/app/hooks/useProductListingLocation'
import useHomepageLocation from '#src/app/hooks/useHomepageLocation'
import { TopListEntryPointClickedEvent } from '#src/app/events/TopListEntryPointClickedEvent'
import { ArticleEntryPointClickedEvent } from '#src/app/events/ArticleEntryPointClickedEvent'
import { DepartmentValue, useDepartment } from '#src/app/context/department'
import { useIdentityContext } from '#src/app/containers/Identity'
import ReleaseConsumerIdentityLink from '#src/app/components/ReleaseConsumerIdentityLink'
import ExpertAvatar from '#src/app/components/ExpertAvatarLegacy'
import {
  NavigationGroupForGetClosestNavigationGroupForDepartmentFragment,
  useDesktopHeaderQuery
} from './DesktopHeader.generated'
import SearchRebrandedIcon from '../icons/search-rebranded.svg'
import RewardsCreditIcon from '../icons/rewards-credit.svg'
import MylistsIcon from '../icons/mylists.svg'
import CurationsIcon from '../icons/curations.svg'
import ChatIcon from '../icons/chat.svg'
import CaretRightIcon from '../icons/caret-right.svg'
import AccountIcon from '../icons/account.svg'
import Search from '../common/Search'
import GlobalBanner from '../../GlobalBanner'
import { CartIconWithBadge } from '../../CartIconWithBadge'
import { HeaderProps } from '../'
import { CLEARANCE_LINKS } from './SaleSection/clearanceLinks'
import SaleSection from './SaleSection'
import CaretDownIcon from './caret-down.svg'
import styles from './styles.css'

function getClosestNavigationGroupForDepartment(
  department: DepartmentValue,
  groups: NavigationGroupForGetClosestNavigationGroupForDepartmentFragment[]
): string {
  if (!department) {
    return groups[0].slug
  }

  // Find the first group where any of the "L2" items shares a department with the current department context
  const match = groups.find(group => {
    return group.navigationRevisions.some(
      navigation => navigation.category?.department?.id === department.id
    )
  })

  return (match || groups[0]).slug
}

let lastSelectedGroup: string

const ChatIconWithBadge: React.FC = () => {
  // ChatContext is asynchronously set, so isolating components that use this state
  // to minimize the amount of components that get re-rendered on page load.
  const { unreadMessageCount } = useContext(ChatContext)

  return (
    <Link to="/inbox">
      <ChatIcon />
      {unreadMessageCount && unreadMessageCount > 0 ? (
        <span className={styles.navListItemBadge}>{unreadMessageCount}</span>
      ) : null}
    </Link>
  )
}

const ViewInboxWithBadge: React.FC = () => {
  // ChatContext is asynchronously set, so isolating components that use this state
  // to minimize the amount of components that get re-rendered on page load.
  const { unreadMessageCount } = useContext(ChatContext)

  return (
    <Link to="/inbox">
      View Inbox
      {unreadMessageCount && unreadMessageCount > 0 ? (
        <span className={styles.navDropDownMenuItemActivityIndicator} />
      ) : null}
    </Link>
  )
}

export const HeaderDesktop: React.FC<HeaderProps> = ({ onHeaderHeightChanged }) => {
  const history = useHistory()
  const productListingLocation = useProductListingLocation()
  const { myself, incompleteUser } = useIdentityContext()
  const [isSearchVisible, updateSearchVisibility] = useShowSearchBar()
  const department = useDepartment()

  const homepageLocation = useHomepageLocation()

  const eligibleForFeedHomePage = useFeedHomePageEligibilityCheck()

  const isImpersonating = myself && myself.user.id !== myself.realUser.id

  const [createPath, { loading: pathLoading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      } else {
        throw new Error('Error creating path for `Description` component!')
      }
    },
    onError: (response: { errorMessage: string }) => {
      const { errorMessage } = response
      toast.error(errorMessage)
    }
  })

  const referralUrl = useReferralUrl({
    source: 'nav-bar'
  })

  // Disabling the hover styles on the mega menu forces the mega menu to be hidden. This is done temporarily
  //   any time the user clicks within the mega menu. Whenever this state is changed, it is changed back
  //   100ms later, to re-enable the hover functionality.
  const [megaMenuHoverEffectEnabled, setMegaMenuHoverEffectEnabled] = useState(true)
  useEffect(() => {
    if (megaMenuHoverEffectEnabled) {
      return
    }

    const timeout = setTimeout(() => {
      setMegaMenuHoverEffectEnabled(true)
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [megaMenuHoverEffectEnabled])

  const { data } = useDesktopHeaderQuery({
    variables: {
      userId: myself?.user.id,
      isLoggedIn: !!myself && !incompleteUser
    }
  })

  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
    department && data
      ? getClosestNavigationGroupForDepartment(department, data.formattedCategoryNavigationsByGroup)
      : lastSelectedGroup
  )

  useEffect(() => {
    if (department && data) {
      setSelectedGroup(
        getClosestNavigationGroupForDepartment(department, data.formattedCategoryNavigationsByGroup)
      )
    }
  }, [department, data])

  useEffect(() => {
    if (selectedGroup) {
      lastSelectedGroup = selectedGroup
    }
  }, [selectedGroup])

  const selectedDecoratedGroup = data?.formattedCategoryNavigationsByGroup.find(
    (group, index) => selectedGroup === group.slug || (selectedGroup === undefined && index === 0)
  )

  const leads = data?.leads?.edges.map(edge => edge.node).filter(lead => !isAILead(lead))

  const [hidden, setHidden] = useState(false)
  useScrollPosition({
    effect: ({ currPos, verticalDirection }) => {
      if (verticalDirection === 'up') {
        setHidden(false)
      } else {
        setHidden(currPos.y < -145)
      }
    },
    wait: 100
  })

  useEffect(() => {
    setHidden(false)
  }, [history.location])

  useEffect(() => {
    onHeaderHeightChanged(hidden ? 0 : 145)
  }, [hidden])

  const isClearanceShown =
    useExperiment({ experiment: 'navigation-final-sale', defaultTreatment: 'control' }).result ===
    'on'

  return (
    <header
      className={classnames(styles.wrapper, {
        [styles.hidden]: hidden,
        [styles.exposedSearch]: true
      })}
    >
      <GlobalBanner />
      <div
        className={classnames(styles.header, {
          [styles.isImpersonating]: isImpersonating
        })}
      >
        <div className={styles.topBarWrapper}>
          <Grid>
            <Area column="span 6">
              <div className={styles.topBar}>
                <Link to={homepageLocation} className={styles.exposedLogo} aria-label="Home">
                  <BrandLogo color="black" />
                </Link>
                <nav aria-label="primary" className={styles.primaryNav}>
                  <ul className={styles.navList}>
                    {data?.formattedCategoryNavigationsByGroup.map(group => {
                      return (
                        <li
                          className={classnames(styles.navListItem, {
                            [styles.navListItemActive]: selectedDecoratedGroup?.slug === group.slug
                          })}
                          onClick={() => {
                            setSelectedGroup(group.slug)
                          }}
                          key={group.slug}
                        >
                          {group.label}
                        </li>
                      )
                    })}
                  </ul>
                </nav>
                <a
                  onClick={() => updateSearchVisibility(true)}
                  data-testid="desktop-nav-search-menu-button"
                  className={styles.searchTriggerButton}
                >
                  <SearchRebrandedIcon className={styles.searchTriggerIcon} />
                  Search products
                </a>
                <nav aria-label="tertiary" className={styles.tertiaryNav}>
                  <ul className={styles.navList}>
                    {myself && !incompleteUser ? (
                      <li className={styles.inboxNavListItem}>
                        <ChatIconWithBadge />

                        <ul className={styles.inboxDropDownMenu}>
                          {leads && leads.length > 0 && (
                            <li>
                              <span className={styles.navDropDownMenuSectionTitle}>Curations</span>
                              <ul>
                                {leads.slice(0, 3).map(lead => {
                                  return (
                                    <li className={styles.navDropDownMenuItem} key={lead.id}>
                                      <Link
                                        to={`/curations/products/${lead.id}`}
                                        className={styles.curation}
                                      >
                                        <div>{lead.department.displayName}</div>
                                        <div className={styles.curationExpert}>
                                          <ExpertAvatar
                                            size={20}
                                            expert={lead.expert}
                                            className={styles.curationExpertAvatar}
                                          />
                                          by {lead.expert.shortName}
                                        </div>
                                      </Link>
                                    </li>
                                  )
                                })}
                              </ul>
                            </li>
                          )}
                          {leads && leads.length > 3 && (
                            <li>
                              <ul>
                                <li className={styles.navDropDownMenuItem}>
                                  <Link to="/curations">
                                    View {leads.length - 3} more curations
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          )}
                          <li>
                            <ul>
                              <li className={styles.navDropDownMenuItem}>
                                <ViewInboxWithBadge />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      <li className={styles.inboxNavListItem}>
                        <Link to="/curations" aria-label="View your curations">
                          <CurationsIcon />
                        </Link>
                      </li>
                    )}
                    <li className={styles.navListItem}>
                      <CartIconWithBadge />
                    </li>
                    {myself && !incompleteUser && (
                      <li className={styles.accountNavListItem}>
                        <Link to="/account/personal-information">
                          <AccountIcon />
                          <span className={styles.accountName}>
                            {myself.user.firstName}
                            {isImpersonating ? ` (${myself.realUser.firstName})` : ''}
                          </span>
                          <CaretDownIcon />
                        </Link>

                        <ul className={styles.accountDropDownMenu}>
                          <li>
                            <ul>
                              <li className={styles.navDropDownMenuItem}>
                                <Link to="/account/personal-information">
                                  <AccountIcon />
                                  Account
                                </Link>
                              </li>
                              <li className={styles.navDropDownMenuItem}>
                                <Link to="/curations">
                                  <CurationsIcon />
                                  My Curations
                                </Link>
                              </li>
                              {myself.influencer && (
                                <li className={styles.navDropDownMenuItem}>
                                  <Link
                                    to={`/mylists/${
                                      myself?.influencer.profile.vanityId ||
                                      myself?.influencer.profile.id
                                    }`}
                                  >
                                    <MylistsIcon />
                                    My Lists
                                  </Link>
                                </li>
                              )}
                              <li className={styles.navDropDownMenuItem}>
                                <Link to="/account/rewards-credit">
                                  <RewardsCreditIcon />
                                  Reward Credit
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <ul>
                              <li className={styles.navDropDownMenuItem}>
                                <Link to="/account/orders">My Orders</Link>
                              </li>
                              <li className={styles.navDropDownMenuItem}>
                                <Link to="/account/gift-cards">Gift Cards</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <ul>
                              <li className={styles.navDropDownMenuItem}>
                                <Link to="/support">Help Center</Link>
                              </li>
                              <li className={styles.navDropDownMenuItem}>
                                <Link to="/auth/logout">Sign Out</Link>
                              </li>
                            </ul>
                          </li>

                          {isImpersonating && (
                            <li>
                              <ul>
                                <li className={styles.navDropDownMenuItem}>
                                  <ReleaseConsumerIdentityLink
                                    className={styles.stopImpersonationLink}
                                  >
                                    Stop impersonating {myself.user.firstName}
                                  </ReleaseConsumerIdentityLink>
                                </li>
                              </ul>
                            </li>
                          )}
                        </ul>
                      </li>
                    )}
                    {(!myself || incompleteUser) && (
                      <>
                        <li className={styles.navListItem}>
                          <Link to="/auth/login">Sign In</Link>
                        </li>
                        <li className={styles.navListItem}>
                          <Link to="/auth/register" className={styles.registrationLink}>
                            Register
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </div>
            </Area>
          </Grid>
        </div>

        <div className={styles.bottomBarWrapper}>
          <Grid>
            <Area column="span 6">
              <div className={styles.bottomBar}>
                <nav aria-label="secondary" className={styles.secondaryNav}>
                  <ul className={styles.navList}>
                    {selectedDecoratedGroup?.navigationRevisions.map(navigation => {
                      const navCategory = navigation.category
                      const expertCount = navCategory
                        ? getActiveExpertCountForCategory(navCategory)
                        : 0
                      const bannerImgUrl =
                        navCategory?.categoryAssetRevision?.bannerImages?.images[0]?.image.url
                      const hasLead = !!leads?.length
                      const renderTopList =
                        navigation.associatedSellableEditorialTopLists.length != 0

                      const navigationLabelDataTestId = `desktop-nav-level-two-item-label-${
                        navigation.category?.slug ||
                        navigation.displayLabel.toLowerCase().replace(' ', '-')
                      }`
                      return (
                        <li
                          key={navigation.id}
                          className={classnames(styles.navListItem, {
                            [styles.navListItemUnhoverable]: megaMenuHoverEffectEnabled === false
                          })}
                          onClick={e => {
                            // If this event originated from an element that is a child of an <a>,
                            //   assume we are navigating and close the mega menu.
                            if (e.target instanceof HTMLElement && e.target.closest('a') !== null) {
                              setMegaMenuHoverEffectEnabled(false)
                            }
                          }}
                        >
                          {navCategory ? (
                            <Link
                              to={buildCategoryOrProductListingPageUrl(navCategory)}
                              className={styles.navListItemLabel}
                              data-testid={navigationLabelDataTestId}
                            >
                              {navigation.displayLabel}
                            </Link>
                          ) : (
                            <span
                              className={styles.navListItemLabel}
                              data-testid={navigationLabelDataTestId}
                            >
                              {navigation.displayLabel}
                            </span>
                          )}

                          <div
                            className={styles.megaMenu}
                            data-testid={`desktop-nav-mega-menu-${
                              navigation.category?.slug ||
                              navigation.displayLabel.toLowerCase().replace(' ', '-')
                            }`}
                          >
                            <Grid>
                              <Area column="span 6">
                                <div className={styles.megaMenuContent}>
                                  {navCategory && !hasLead && (
                                    <div className={styles.megaMenuFirstColumn}>
                                      <ul className={styles.megaMenuFirstColumnLinkList}>
                                        <li className={styles.megaMenuLinkListItem}>
                                          <Link
                                            to={buildCategoryOrProductListingPageUrl(navCategory)}
                                            className={styles.megaMenuHeading}
                                          >
                                            Shop All
                                            <CaretRightIcon
                                              className={styles.megaMenuHeadingCaret}
                                            />
                                          </Link>
                                        </li>
                                      </ul>
                                      {/* Path CTA / Link to lead */}
                                      {bannerImgUrl && (
                                        <Image
                                          src={bannerImgUrl}
                                          className={styles.megaMenuCategoryImage}
                                          htmlAttributes={{
                                            alt: navigation.displayLabel
                                          }}
                                          height={120}
                                          width={200}
                                          imgixParams={{ fit: 'crop' }}
                                          lazyLoad={false}
                                        />
                                      )}
                                      <div className={styles.megaMenuCategoryExpertFacepile}>
                                        {navCategory.experts.map(expert => (
                                          <ExpertAvatar
                                            key={expert.id}
                                            expert={expert}
                                            size={40}
                                            className={styles.megaMenuCategoryExpertAvatar}
                                          />
                                        ))}
                                      </div>
                                      <div className={styles.megaMenuCategoryExpertInfo}>
                                        <strong>{expertCount}</strong>
                                        {navCategory.singularDisplayName} Expert
                                        {expertCount > 1 ? 's' : ''} available to chat
                                        <Button
                                          variant="neutral-dark"
                                          className={styles.megaMenuCategoryPathCta}
                                          onClick={() => {
                                            createPath({
                                              pathSelector: {
                                                categorySlugHierarchyLookup: navCategory.slug
                                              },
                                              trackingCodes: {
                                                ctaName: 'desktop-nav-mega-menu-cta',
                                                sourceKey: 'desktop-nav-mega-menu-cta'
                                              }
                                            })
                                          }}
                                          disabled={pathLoading}
                                        >
                                          Get Connected
                                        </Button>
                                      </div>
                                    </div>
                                  )}

                                  <div className={styles.megaMenuMainColumn}>
                                    {navigation.sections.map(section => {
                                      return (
                                        <ul className={styles.megaMenuLinkList} key={section.id}>
                                          <li className={styles.megaMenuLinkListHeading}>
                                            {section.displayLabelLink?.relativeConsumerWebPath ? (
                                              <Link
                                                to={
                                                  section.displayLabelLink.relativeConsumerWebPath
                                                }
                                                rel={
                                                  section.displayLabelLink.seoShouldFollow
                                                    ? undefined
                                                    : 'nofollow'
                                                }
                                              >
                                                {section.displayLabel}
                                                <CaretRightIcon
                                                  className={styles.megaMenuHeadingCaret}
                                                />
                                              </Link>
                                            ) : (
                                              <>{section.displayLabel}</>
                                            )}
                                          </li>

                                          {section.items.map(item => {
                                            return (
                                              <li
                                                className={styles.megaMenuLinkListItem}
                                                key={item.id}
                                              >
                                                {item.displayLabelLink?.relativeConsumerWebPath ? (
                                                  <Link
                                                    to={
                                                      item.displayLabelLink.relativeConsumerWebPath
                                                    }
                                                    rel={
                                                      item.displayLabelLink.seoShouldFollow
                                                        ? undefined
                                                        : 'nofollow'
                                                    }
                                                  >
                                                    {item.displayLabel}
                                                  </Link>
                                                ) : (
                                                  <>{item.displayLabel}</>
                                                )}
                                              </li>
                                            )
                                          })}
                                        </ul>
                                      )
                                    })}

                                    {/* These links exist for every group */}
                                    {navCategory && (
                                      <ul className={styles.megaMenuLinkList}>
                                        <li className={styles.megaMenuLinkListHeading}>Featured</li>
                                        <li className={styles.megaMenuLinkListItem}>
                                          <Link
                                            to={productListingLocation({
                                              categorySlug: navCategory.slug,
                                              sortBy: 'BEST_SELLER',
                                              sortOrder: 'DESC'
                                            })}
                                          >
                                            Best Sellers
                                          </Link>
                                        </li>
                                        <li className={styles.megaMenuLinkListItem}>
                                          <Link
                                            to={productListingLocation({
                                              categorySlug: navCategory.slug,
                                              sortBy: 'POPULARITY',
                                              sortOrder: 'DESC'
                                            })}
                                          >
                                            Trending
                                          </Link>
                                        </li>
                                        <li className={styles.megaMenuLinkListSaleItem}>
                                          <Link
                                            to={productListingLocation({
                                              categorySlug: navCategory.slug,
                                              filters: ['DISCOUNTED']
                                            })}
                                          >
                                            Sale
                                          </Link>
                                        </li>
                                        {isClearanceShown && (
                                          <li className={styles.megaMenuLinkListClearanceItem}>
                                            <Link
                                              to={productListingLocation({
                                                categorySlug: navCategory.slug,
                                                filters: ['CLEARANCE']
                                              })}
                                            >
                                              Clearance sale
                                            </Link>
                                          </li>
                                        )}
                                      </ul>
                                    )}
                                  </div>

                                  <div className={styles.megaMenuThirdColumn}>
                                    <ul className={styles.megaMenuThirdColumnLinkList}>
                                      {navCategory && hasLead && (
                                        <li className={styles.megaMenuLinkListItem}>
                                          <Link
                                            to={buildCategoryOrProductListingPageUrl(navCategory)}
                                            className={styles.megaMenuHeading}
                                          >
                                            Shop All
                                            <CaretRightIcon
                                              className={styles.megaMenuHeadingCaret}
                                            />
                                          </Link>
                                        </li>
                                      )}
                                      <li className={styles.megaMenuLinkListItem}>
                                        <Link to="/journal" className={styles.megaMenuHeading}>
                                          Expert Journal
                                          <CaretRightIcon className={styles.megaMenuHeadingCaret} />
                                        </Link>
                                      </li>
                                    </ul>
                                    {navigation.associatedArticles.length > 0 && (
                                      <div className={styles.megaMenuArticles}>
                                        {navigation.associatedArticles
                                          .slice(0, renderTopList ? 1 : 2)
                                          .map(article => (
                                            <Link
                                              to={`/journal/${article.friendlyId}/${article.slug}`}
                                              className={styles.megaMenuArticle}
                                              key={article.id}
                                              onClick={() => {
                                                window.tracking?.track(
                                                  new ArticleEntryPointClickedEvent({
                                                    article_id: article.id,
                                                    entry_point: 'header'
                                                  })
                                                )
                                              }}
                                            >
                                              {article.featuredImage && (
                                                <Image
                                                  id={article.featuredImage.media.id}
                                                  src={article.featuredImage.media.url}
                                                  className={styles.megaMenuArticleImage}
                                                  width={210}
                                                  height={120}
                                                  imgixParams={{ fit: 'crop' }}
                                                  lazyLoad={false}
                                                />
                                              )}
                                              <div className={styles.megaMenuArticleTitle}>
                                                {article.title}
                                              </div>
                                            </Link>
                                          ))}
                                      </div>
                                    )}
                                    {renderTopList && (
                                      <ul className={styles.megaMenuTopLists}>
                                        <span className={styles.megaMenuHeading}>Top Lists</span>
                                        {navigation.associatedSellableEditorialTopLists.map(
                                          topList => {
                                            const image = topList.firstItem?.sellable.primaryImage
                                            return (
                                              <Link
                                                to={`/top-lists/${topList.friendlyId}/${topList.slug}`}
                                                key={topList.id}
                                                onClick={() =>
                                                  window.tracking?.track(
                                                    new TopListEntryPointClickedEvent({
                                                      entry_point: 'header-nav',
                                                      top_list_id: topList.id
                                                    })
                                                  )
                                                }
                                              >
                                                <li className={styles.megaMenuTopList}>
                                                  {image && (
                                                    <div
                                                      className={
                                                        styles.megaMenuTopListImageContainer
                                                      }
                                                    >
                                                      <Image
                                                        id={image.id}
                                                        src={image.url}
                                                        className={styles.megaMenuTopListImage}
                                                        sizes="20px"
                                                      />
                                                    </div>
                                                  )}
                                                  <div className={styles.megaMenuTopListTitle}>
                                                    {topList.title}
                                                  </div>
                                                </li>
                                              </Link>
                                            )
                                          }
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              </Area>
                            </Grid>
                          </div>
                        </li>
                      )
                    })}

                    {/* Sale section */}
                    <li
                      key="sale"
                      className={classnames(styles.navListItem, {
                        [styles.navListItemUnhoverable]: megaMenuHoverEffectEnabled === false
                      })}
                      onClick={e => {
                        // If this event originated from an element that is a child of an <a>,
                        //   assume we are navigating and close the mega menu.
                        if (e.target instanceof HTMLElement && e.target.closest('a') !== null) {
                          setMegaMenuHoverEffectEnabled(false)
                        }
                      }}
                    >
                      <Link to="/sale" className={styles.navListItemLabel}>
                        <b>Sale</b>
                      </Link>
                      <div className={styles.megaMenu}>
                        <SaleSection />
                      </div>
                    </li>
                    {/* Clearance section */}
                    {isClearanceShown && (
                      <li
                        key="clearance"
                        className={classnames(styles.navListItem, {
                          [styles.navListItemUnhoverable]: megaMenuHoverEffectEnabled === false
                        })}
                        onClick={e => {
                          // If this event originated from an element that is a child of an <a>,
                          //   assume we are navigating and close the mega menu.
                          if (e.target instanceof HTMLElement && e.target.closest('a') !== null) {
                            setMegaMenuHoverEffectEnabled(false)
                          }
                        }}
                      >
                        <Link
                          to="/clearance"
                          className={classnames(styles.navListItemLabel, styles.cleranceLabel)}
                        >
                          <b>Clearance sale</b>
                        </Link>
                        <div className={styles.megaMenu}>
                          <SaleSection
                            title="Clearance sale"
                            filters={['CLEARANCE']}
                            sortBy={'MAX_PRICE_PERCENT_SAVINGS'}
                            topLink={{ label: 'Shop All Clearance sales', link: '/clearance' }}
                            banner={{
                              heading: 'UP TO 50% OFF',
                              body: 'Shop Clearance sales and get the most out of your budget',
                              cta: {
                                label: 'Shop Clearance sales',
                                link: '/clearance'
                              },
                              trackingCodes: {
                                ctaName: 'clearance-desktop-nav',
                                sourceKey: 'clearance-desktop-nav'
                              }
                            }}
                            links={CLEARANCE_LINKS}
                          />
                        </div>
                      </li>
                    )}
                  </ul>
                </nav>
                <nav aria-label="referrals-and-reviews" className={styles.referralsAndReviewsNav}>
                  <ul className={styles.navList}>
                    <li className={styles.navListItem}>
                      <span className={styles.navListItemLabel}>
                        <Link to={'/curated-lists'}>Curated Lists</Link>
                      </span>
                    </li>
                    {referralUrl && (
                      <li className={styles.navListItem}>
                        <span className={styles.navListItemLabel}>
                          <Link to={referralUrl}>Refer a friend</Link>
                        </span>
                      </li>
                    )}
                    {eligibleForFeedHomePage ? (
                      <li className={styles.navListItem}>
                        <span className={styles.navListItemLabel}>
                          <Link to="/for-you">Community</Link>
                        </span>
                      </li>
                    ) : (
                      <li className={styles.reviewsNavListItem}>
                        <a className={styles.navListItemLabel}>
                          <span>Reviews</span>
                          <CaretDownIcon />
                        </a>

                        <ul className={styles.reviewsDropDownMenu}>
                          <li className={styles.navDropDownMenuItem}>
                            <Link to={department ? `/reviews/${department.slug}` : '/reviews'}>
                              Reviews
                            </Link>
                          </li>
                          <li className={styles.navDropDownMenuItem}>
                            <Link to="/company/experts">Meet the Experts</Link>
                          </li>
                          <li className={styles.navDropDownMenuItem}>
                            <Link to="/support/faq">FAQ</Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </Area>
          </Grid>
        </div>
      </div>

      <Search isOpen={isSearchVisible} onRequestClose={() => updateSearchVisibility(false)} />
    </header>
  )
}
