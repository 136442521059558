export const CLEARANCE_LINKS = [
  {
    label: 'Outdoor Sports',
    sections: [
      {
        label: 'Snowboarding',
        link: { categorySlug: 'snowboarding', selections: undefined }
      },
      {
        label: 'Skiing',
        link: { categorySlug: 'skiing', selections: undefined }
      },
      // {
      //   label: 'Golf',
      //   link: { categorySlug: 'golf', selections: undefined }
      // },
      {
        label: 'Cycling',
        link: { categorySlug: 'cycling', selections: undefined }
      },
      {
        label: 'Camping & Hiking',
        link: { categorySlug: 'camping', selections: undefined }
      },
      {
        label: 'Fly Fishing',
        link: { categorySlug: 'fly-fishing', selections: undefined }
      },
      {
        label: 'Conventional fishing',
        link: { categorySlug: 'conventional-fishing', selections: undefined }
      },
      {
        label: 'Tennis',
        link: { categorySlug: 'tennis', selections: undefined }
      }
    ]
  },
  {
    label: 'Baby',
    sections: [
      {
        label: 'Gear',
        link: { categorySlug: 'baby-toddler-gear', selections: undefined }
      }
    ]
  },
  {
    label: "Men's Apparel",
    sections: [
      {
        label: 'Snow',
        link: {
          categorySlug: 'winter-sports-apparel',
          selections: {
            g: {
              name: 'g',
              bucketValues: ['male', 'unisex']
            }
          }
        }
      },
      // {
      //   label: 'Golf',
      //   link: { categorySlug: 'golf-apparel/g/male', selections: undefined }
      // },
      {
        label: 'Cycling',
        link: {
          categorySlug: 'cycling-apparel',
          selections: {
            g: {
              name: 'g',
              bucketValues: ['male', 'unisex']
            }
          }
        }
      },
      {
        label: 'Camping & Hiking',
        link: {
          categorySlug: 'camping-apparel',
          selections: {
            g: {
              name: 'g',
              bucketValues: ['male', 'unisex']
            }
          }
        }
      },
      {
        label: 'Fishing',
        link: {
          categorySlug: 'fishing-apparel',
          selections: {
            g: {
              name: 'g',
              bucketValues: ['male', 'unisex']
            }
          }
        }
      }
    ]
  },
  {
    label: "Women's Apparel",
    sections: [
      {
        label: 'Snow',
        link: {
          categorySlug: 'winter-sports-apparel',
          selections: {
            g: {
              name: 'g',
              bucketValues: ['female', 'unisex']
            }
          }
        }
      },
      // {
      //   label: 'Golf',
      //   link: { categorySlug: 'golf-apparel/g/female', selections: undefined }
      // },
      {
        label: 'Cycling',
        link: {
          categorySlug: 'cycling-apparel',
          selections: {
            g: {
              name: 'g',
              bucketValues: ['female', 'unisex']
            }
          }
        }
      },
      {
        label: 'Camping & Hiking',
        link: {
          categorySlug: 'camping-apparel',
          selections: {
            g: {
              name: 'g',
              bucketValues: ['female', 'unisex']
            }
          }
        }
      },
      {
        label: 'Fishing',
        link: {
          categorySlug: 'fishing-apparel',
          selections: {
            g: {
              name: 'g',
              bucketValues: ['female', 'unisex']
            }
          }
        }
      }
    ]
  }
]
