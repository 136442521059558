import React from 'react'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import { useCart } from '#src/app/context/cart'
import CartIcon from './cart.svg'
import styles from './styles.css'

interface CartIconWithBadgeProps {
  isHiddenWhenEmpty?: boolean
}

export const CartIconWithBadge: React.FC<CartIconWithBadgeProps> = ({ isHiddenWhenEmpty }) => {
  const { setCartVisibility } = useCartVisibilityContext()
  const { cart } = useCart('CART_VIEW')

  const cartLineItemCount = cart?.purchasableItemsCount

  if (isHiddenWhenEmpty && (cartLineItemCount ?? 0) === 0) {
    return null
  }

  return (
    <button
      className={styles.navButton}
      onClick={() => setCartVisibility(true)}
      data-testid="desktop-nav-cart-button"
      aria-label="Cart"
    >
      <CartIcon />
      {cartLineItemCount !== undefined && cartLineItemCount > 0 && (
        <span className={styles.navListItemBadge}>{cartLineItemCount}</span>
      )}
    </button>
  )
}
