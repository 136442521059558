import React from 'react'
import { toast } from 'react-toastify'
import { Link, useHistory } from '@deal/router'
import { Button, Heading, Text } from '@deal/bluxome'
import { SellableSearchFilter, SellableSort } from '#src/generated/types'
import { FacetSelectionsMap } from '#src/app/services/search/types'
import { TrackingCodes } from '#src/app/services/path/hooks/useCreateRequestPath'
import { formatPathUrl, getOnboardingPathSlug, useCreateRequestPath } from '#src/app/services/path'
import { useProductListingLocation } from '#src/app/hooks/useProductListingLocation'
import PageContent from '#src/app/components/PageContent'
import CaretRightIcon from '../../icons/caret-right.svg'
import { DEALS_LINKS } from './dealsLinks'
import styles from './styles.css'

interface SaleSectionProps {
  title?: string
  filters?: SellableSearchFilter[]
  sortBy?: SellableSort
  topLink?: {
    label: string
    link: string
  }
  banner?: {
    heading: string
    body: string
    cta: {
      label: string
      link: string
    }
    trackingCodes: TrackingCodes
  }
  links?: {
    label: string
    sections: {
      label: string
      link: {
        categorySlug: string
        selections?: FacetSelectionsMap
      }
    }[]
  }[]
}

const SaleSection: React.FC<SaleSectionProps> = ({
  title = 'Sale',
  filters = ['DISCOUNTED'],
  sortBy,
  topLink = { label: 'Shop All Sales', link: '/sale' },
  banner = {
    heading: 'UP TO 50% OFF',
    body: 'Shop thousands of markdowns with a Real Expert',
    cta: {
      label: 'Shop Deals',
      link: '/sale'
    },
    trackingCodes: {
      ctaName: 'sale-desktop-nav',
      sourceKey: 'sale-desktop-nav'
    }
  },
  links = DEALS_LINKS
}) => {
  const history = useHistory()
  const productListingLocation = useProductListingLocation()

  const link = (categorySlug: string, selections?: FacetSelectionsMap) => {
    return productListingLocation({
      categorySlug,
      filters: filters,
      selections: selections,
      sortBy: sortBy
    })
  }

  const labelToLink = links.map(group => {
    return {
      label: group.label,
      sections: group.sections.map(section => {
        return {
          label: section.label,
          link: link(section.link.categorySlug, section.link.selections)
        }
      })
    }
  })

  const [createPath, { loading: pathLoading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      } else {
        throw new Error('Error creating path for `SaleSection` component!')
      }
    },
    onError: (response: { errorMessage: string }) => {
      const { errorMessage } = response
      toast.error(errorMessage)
    }
  })

  return (
    <PageContent>
      <div className={styles.megaMenuContent}>
        <div className={styles.megaMenuMainColumn}>
          <div className={styles.megaMenuMainColumnHeader}>
            <h2 className={styles.megaMenuMainColumnHeaderTitle}>{title}</h2>
            <Link className={styles.megaMenuMainColumnHeaderText} to={topLink.link}>
              {topLink.label}
              <CaretRightIcon className={styles.megaMenuMainColumnHeadingCaret} />
            </Link>
          </div>
          <div className={styles.megaMenuMainColumnBody}>
            {labelToLink.slice(0, 3).map(group => {
              return (
                <ul className={styles.megaMenuLinkList} key={group.label}>
                  <li className={styles.megaMenuLinkListHeading}>{group.label}</li>

                  {group.sections.map(section => {
                    return (
                      <li className={styles.megaMenuLinkListItem} key={section.label}>
                        <Link to={section.link} rel="follow">
                          {section.label}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              )
            })}
            <ul className={styles.megaMenuLinkList}>
              {labelToLink.slice(3).map(group => {
                return (
                  <li className={styles.megaMenuLinkListSection} key={group.label}>
                    <ul className={styles.megaMenuLinkList}>
                      <li className={styles.megaMenuLinkListHeading}>{group.label}</li>

                      {group.sections.map(section => {
                        return (
                          <li className={styles.megaMenuLinkListItem} key={section.label}>
                            <Link to={section.link}>{section.label}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className={styles.megaMenuRightColumn}>
          <div className={styles.megaMenuRightColumnTitle}>
            <Heading size="h1">{banner.heading}</Heading>
          </div>
          <div className={styles.megaMenuRightColumnBody}>
            <Text style="base-medium">{banner.body}</Text>
          </div>
          <div className={styles.cta}>
            <Button
              color="learn-blue"
              isDisabled={pathLoading}
              onPress={() => {
                createPath({
                  pathSelector: {
                    pathSchemaSlug: getOnboardingPathSlug()
                  },
                  trackingCodes: banner.trackingCodes
                })
              }}
            >
              Shop with a Real Expert
            </Button>
            <Button link={{ to: banner.cta.link }} style="outline" color="neutral-dark">
              {banner.cta.label}
            </Button>
          </div>
        </div>
      </div>
    </PageContent>
  )
}

export default SaleSection
